import * as React from "react"

import Layout from "../components/layout/layout"

const DolniPiqnitzi = () => (
  <Layout>
    asd
  </Layout>
)

export default DolniPiqnitzi
